import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import HomeTwoColumn from "../components/homeTwoColumn"
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import "./index.css";
import favicon from "../images/favicon.ico";
import ContactButtons from "../components/ContactButtons";
import CallToAction from "../components/CallToAction";


export default function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <link rel="canonical" href="http://www.newnewyorkcapital.com" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Layout>
        <HomeTwoColumn />
        <div className="flexButtons">
          <CallToAction>
            See Our Work
        </CallToAction>
          <ContactButtons className="centerMargin contactButtons" />
        </div>


      </Layout>
    </div>
  )
}
